import { useState, useEffect } from 'react';
import NavBar from "../../Components/Navbar/Navbar";
import Banner from "../../Components/Banner/Banner";

function Mentors(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

    return <>
        <NavBar i18n={props.i18n} t={props.t} />
        <Banner
            id="main-banner"
            image="./images\banner\banner images\mentors.png"
            bannerHeader={props.t("banner.header.mentors")}
            headerWidth={viewport > 991 ? "39.5313vw": "90.3308vw"} 
            bannerContent={props.t("banner.content.mentors")}
            contentWidth={viewport > 991 ? "36.7188vw": "90.3308vw"} />
    </>
}

export default Mentors;