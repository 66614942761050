import "./Banner.css"
import Button from "../../Components/Banner/Button/Button"


function Banner(props) {
    
    return <>
        <div className="container-fluid" id={props.id}>
            <div className="row" id="inner-banner">
                <div className="col-12 col-lg-6" id="banner-image-container">
                    <img id="banner-image" src={props.image}></img>
                </div>
                <div className="col-12 col-lg-6" id="banner-text-container">
                    <div id="banner-text">
                         {props.id === "home-banner"
                         ?<h1 id="banner-header" style={{ width: props.headerWidth }}>{props.bannerHeader}</h1>
                        : <h2 id="banner-header" style={{ width: props.headerWidth }}>{props.bannerHeader}</h2>}
                        
                        {props.id === "home-banner"
                            ? <div id="store-button-container" style={{ width: props.contentWidth }}>
                                <Button icon = {"./images/banner/store icons/appstore.svg"} qr = {"./images/banner/store icons/appstore-QR.svg"} accompanyingText= {"Download on the"} storeName = {"App Store"}/>
                                <Button icon = {"./images/banner/store icons/playstore.svg"} qr = {"./images/banner/store icons/playstore-QR.svg" } accompanyingText= {"Download on the"} storeName = {"Play Store"}/>
                            </div>
                            : <p id="banner-content" style={{ width: props.contentWidth }} >{props.bannerContent}</p>}
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Banner;