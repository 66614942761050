import { useState, useEffect } from 'react';
import NavBar from "../../Components/Navbar/Navbar";
import Banner from "../../Components/Banner/Banner";
import Member from '../../Components/Member/Member';
import "./Achievements.css";

function Achievements(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

    return <>
        <NavBar i18n={props.i18n} t={props.t} />
        <Banner
            id="main-banner"
            image="./images\banner\banner images\achievements.png"
            bannerHeader={props.t("banner.header.achievements")}
            headerWidth={viewport > 991 ? "27.7604vw" : "90.3308vw"}
            bannerContent={props.t("banner.content.achievements")}
            contentWidth={viewport > 991 ? "36.7188vw" : "90.3308vw"} />
        <div className='achievements-container' id="achievements-graph-container">
            <h3 className='achievements-header'>{props.t("achievements.achievements-graph.header")}</h3>
            <p className="achievements-paragraph">{props.t("achievements.achievements-graph.paragraph")}</p>
            <div id="achievements-graph"></div>
        </div>
        <div className='achievements-container' id="achievements-2023-2024-container">
            <h3 className='achievements-header'>{props.t("achievements.2023-2024.header")}</h3>
            <p className="achievements-paragraph" >{props.t("achievements.2023-2024.paragraph1")}</p>
            <div className="container-fluid ">
                <div className='row achievements-inner-container' >
                    <Member
                        image="/images/images/achievements/2023-2024/Hakob\ Ayvazyan.png"
                        name={props.t("achievements.2023-2024.members.name.member1")}
                        content={props.t("achievements.2023-2024.members.content.member1")}
                        height={viewport > 991 ? "23.1558vw" : "56.9863vw"}
                        width={viewport > 991 ? "12.7083vw" : "41.9847vw"}
                        radius={viewport > 991 ? "9.8177vw" : "47.9643vw"}
                        class="col-6 col-lg-2" />
                    <Member
                        image="/images/images/achievements/2023-2024/Davit\ Badishyan.png"
                        name={props.t("achievements.2023-2024.members.name.member2")}
                        content={props.t("achievements.2023-2024.members.content.member2")}
                        height={viewport > 991 ? "23.1558vw" : "56.9863vw"}
                        width={viewport > 991 ? "12.7083vw" : "41.9847vw"}
                        radius={viewport > 991 ? "9.8177vw" : "47.9643vw"}
                        class="col-6 col-lg-2" margin={" margin-top"} />
                    <Member
                        image="/images/images/achievements/2023-2024/Arman\ Kirakosyan.png"
                        name={props.t("achievements.2023-2024.members.name.member3")}
                        content={props.t("achievements.2023-2024.members.content.member3")}
                        height={viewport > 991 ? "23.1558vw" : "56.9863vw"}
                        width={viewport > 991 ? "12.7083vw" : "41.9847vw"}
                        radius={viewport > 991 ? "9.8177vw" : "47.9643vw"}
                        class="col-6 col-lg-2" />
                    <Member
                        image="/images/images/achievements/2023-2024/Boris\ Muradyan.png"
                        name={props.t("achievements.2023-2024.members.name.member4")}
                        content={props.t("achievements.2023-2024.members.content.member4")}
                        height={viewport > 991 ? "23.1558vw" : "56.9863vw"}
                        width={viewport > 991 ? "12.7083vw" : "41.9847vw"}
                        radius={viewport > 991 ? "9.8177vw" : "47.9643vw"}
                        class="col-6 col-lg-2"
                        margin={"margin-top"} />
                    <Member
                        image="/images/images/achievements/2023-2024/Hayk\ Tonoyan.png"
                        name={props.t("achievements.2023-2024.members.name.member5")}
                        content={props.t("achievements.2023-2024.members.content.member5")}
                        height={viewport > 991 ? "23.1558vw" : "56.9863vw"}
                        width={viewport > 991 ? "12.7083vw" : "41.9847vw"}
                        radius={viewport > 991 ? "9.8177vw" : "47.9643vw"}
                        class="col-6 col-lg-2" />
                    <Member
                        image="/images/images/achievements/2023-2024/Sevada\ Harutyunyan.png"
                        name={props.t("achievements.2023-2024.members.name.member6")}
                        content={props.t("achievements.2023-2024.members.content.member6")}
                        height={viewport > 991 ? "23.1558vw" : "56.9863vw"}
                        width={viewport > 991 ? "12.7083vw" : "41.9847vw"}
                        radius={viewport > 991 ? "9.8177vw" : "47.9643vw"}
                        class="col-6 col-lg-2"
                        margin={"margin-top"} />

                </div>
            </div>
        </div>
    </>
}

export default Achievements;