import { useState, useEffect } from 'react';
import NavBar from "../../Components/Navbar/Navbar";
import Banner from "../../Components/Banner/Banner";
import NewsCard from '../../Components/NewsCard/NewsCard';
import "./News.css"

function News(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

    return <>
        <NavBar i18n={props.i18n} t={props.t} />
        <Banner
            id="main-banner"
            image="./images\banner\banner images\news.png"
            bannerHeader={props.t("banner.header.news")}
            headerWidth={viewport > 991 ? "34.2188vw" : "90.3308vw"}
            bannerContent={props.t("banner.content.news")}
            contentWidth={viewport > 991 ? "19.8958vw" : "90.3308vw"} />

        <div className='news-container' id="news">
            <h3 className='news-header'>{props.t("news.news.header")}</h3>
            <div className="container-fluid" >
                <div className='row' id = 'news-inner-container'>
                    <NewsCard t = {props.t} content = "news-card.content.news1" date = "news-card.date.news1" image={"/images/news-card/news1.png"} class="col-6 col-lg-3" />
                    <NewsCard t = {props.t} content = "news-card.content.news2" date = "news-card.date.news2" image= {"/images/news-card/news2.png"} class="col-6 col-lg-3" />
                    <NewsCard t = {props.t} content = "news-card.content.news3" date = "news-card.date.news3" image={"/images/news-card/news3.png"} class="col-6 col-lg-3" />
                    <NewsCard t = {props.t} content = "news-card.content.news4" date = "news-card.date.news4" image={"/images/news-card/news4.png"} class="col-6 col-lg-3" />
                </div>
            </div>
        </div>
    </>

}

export default News;