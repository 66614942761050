import { useState, useEffect } from 'react';
import NavBar from "../../Components/Navbar/Navbar";
import Banner from "../../Components/Banner/Banner"; 

function ContactUs(props) {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

    return <>
        <NavBar i18n={props.i18n} t={props.t} />
        <Banner
            id="main-banner"
            image="./images\banner\banner images\contact-us.png"
            bannerHeader={props.t("banner.header.contactUs")}
            headerWidth={viewport > 991 ? "27.2396vw": "90.3308vw"}
            bannerContent={props.t("banner.content.contactUs")}
            contentWidth={viewport > 991 ? "36.7188vw": "90.3308vw"} />
    </>
}

export default ContactUs;