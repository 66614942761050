import { useState, useEffect } from 'react';
import "./Button.css";


function Button(props) {
   const [viewport, setViewport] = useState(document.documentElement.clientWidth);

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setViewport(document.documentElement.clientWidth);
        };

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures this runs only once on mount

   let buttonClasses = `store-button ${viewport >= 992 ? "desktop" : "mobile"}`;
   let iconClasses = `store-icon ${viewport >= 992 ? "desktop" : "mobile"}`;
   let downloadClases = `download-text ${viewport >= 992 ? "desktop" : "mobile"}`;
   let textClasses = `text ${viewport >= 992 ? "desktop" : "mobile"}`;
   let storeClases = `text store ${viewport >= 992 ? "desktop" : "mobile"}`;
   let qrClasses = `qr ${viewport >= 992 ? "desktop" : "mobile"}`;


   return <button className={buttonClasses}>
      <img className={iconClasses} src={props.icon} alt="" />
      <span className={downloadClases}>
         <span className={textClasses}>{props.accompanyingText}</span>
         <span className={storeClases}>{props.storeName}</span>
      </span>
      <img className = {qrClasses} src={props.qr} alt="" />

   </button>


}

export default Button;