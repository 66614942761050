import "./Member.css"


function Member(props) {

    return <>
        <div className={`${props.class} member-container `} >
            <div className={`member-inner-container ${props.margin}`}>
                <div className="member-image" style={{
                    backgroundImage: `url(${props.image})`,
                    height: props.height, 
                    width: props.width, 
                    borderRadius: props.radius}}></div>
                <div>
                    <p id="member-name">{props.name}</p>
                    <p id="member-content">{props.content}</p>
                </div>

            </div>
        </div>
    </>

}

export default Member;