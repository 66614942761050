import "./NewsCard.css"

function NewsCard(props) {
    return <div className="col-lg-4">
        <div className="card" id="news-card">
            <img src={props.image} className="card-img-top" alt="" id="news-card-image" ></img>
                <p id="news-card-content">{props.t(props.content)}</p>
                <div id="link-container">
                    <p id="news-card-date">{props.t(props.date)}</p>
                    <span id = "link-inner-container">
                        <a href="#" id="news-card-link">{props.t("news-card.read")}</a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clipPath="url(#clip0_625_144)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.2071 7.29289C15.5976 7.68342 15.5976 8.31658 15.2071 8.70711L11.2071 12.7071C10.8166 13.0976 10.1834 13.0976 9.79289 12.7071C9.40237 12.3166 9.40237 11.6834 9.79289 11.2929L12.0858 9L1.5 9C0.947715 9 0.5 8.55228 0.5 8C0.5 7.44771 0.947715 7 1.5 7L12.0858 7L9.79289 4.70711C9.40237 4.31658 9.40237 3.68342 9.79289 3.29289C10.1834 2.90237 10.8166 2.90237 11.2071 3.29289L15.2071 7.29289Z" fill="#5F71FE" />
                            </g>
                            <defs>
                                <clipPath id="clip0_625_144">
                                    <rect width="16" height="16" fill="white" transform="translate(16) rotate(90)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                </div>
        </div>
    </div>

}

export default NewsCard;