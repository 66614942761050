// import { changeLanguage } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Achievements from './Pages/Achievements/Achievements';
import News from './Pages/News/News';
import Mentors from './Pages/Mentors/Mentors';
import ContactUs from './Pages/Contact us/Contact_us';
import BecomeAStudent from './Pages/Become a student/Become_a_student';
import NavBar from './Components/Navbar/Navbar';



function App() {

  const { t, i18n } = useTranslation();


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home i18n={i18n} t={t} />} />
          {/* <Route path="/" element={<Home i18n={i18n} t={t} />} /> */}
          <Route path="/achievements" element={<Achievements i18n={i18n} t={t} />} />
          <Route path="/news" element={<News i18n={i18n} t={t} />} />
          <Route path="/mentors" element={<Mentors i18n={i18n} t={t} />} />
          <Route path="/contact-us" element={<ContactUs i18n={i18n} t={t} />} />
          <Route path="/become-a-student" element={<BecomeAStudent i18n={i18n} t={t} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  )

}

export default WrappedApp;