import React, {useState} from "react";
import "./Navbar.css"


const locales = {
    en: { title: "English" },
    hy: { title: "Հայերեն" }
}



function NavBar(props) {


    return <nav className="navbar navbar-expand-lg navbar-light bg sticky-top" id="navbar">
        <div className="container-fluid" id="navbar-inner">
            <a id="navbar-brand" className="navbar-brand" href="#">
                <svg xmlns="http://www.w3.org/2000/svg" width="130" height="42" viewBox="0 0 130 42" fill="none">
                    <path d="M116.557 32.6483C116.783 32.6483 116.976 32.7237 117.136 32.8743C117.296 33.0344 117.376 33.2226 117.376 33.4392C117.376 33.6652 117.296 33.8582 117.136 34.0182C116.976 34.1688 116.783 34.2442 116.557 34.2442H115.385V38.1843C115.385 38.4103 115.305 38.5939 115.145 38.7351C114.994 38.8857 114.806 38.961 114.58 38.961C114.354 38.961 114.166 38.8857 114.015 38.7351C113.864 38.5939 113.789 38.4103 113.789 38.1843V34.2442H113.591C113.375 34.2442 113.187 34.1688 113.026 34.0182C112.876 33.8582 112.801 33.6652 112.801 33.4392C112.801 33.2226 112.876 33.0344 113.026 32.8743C113.187 32.7237 113.375 32.6483 113.591 32.6483H113.789V30.8689C113.789 30.643 113.864 30.4547 114.015 30.304C114.166 30.1534 114.354 30.0781 114.58 30.0781C114.806 30.0781 114.994 30.1534 115.145 30.304C115.305 30.4547 115.385 30.643 115.385 30.8689V32.6483H116.557Z" fill="#101010" />
                    <path d="M129.366 33.0296C129.413 33.1332 129.437 33.2321 129.437 33.3262C129.437 33.4298 129.413 33.5333 129.366 33.6369C129.281 33.844 129.14 33.99 128.942 34.0747C128.425 34.2912 127.652 34.6113 126.626 35.035H126.584V38.1419C126.584 38.3585 126.504 38.5468 126.344 38.7068C126.184 38.8575 125.991 38.9328 125.765 38.9328C125.548 38.9328 125.36 38.8575 125.2 38.7068C125.049 38.5468 124.974 38.3585 124.974 38.1419V33.2697C124.974 33.0438 125.049 32.8508 125.2 32.6907C125.36 32.5306 125.548 32.4506 125.765 32.4506C125.991 32.4506 126.184 32.5306 126.344 32.6907C126.504 32.8508 126.584 33.0438 126.584 33.2697V33.3121C126.876 33.1897 127.459 32.9496 128.335 32.5918C128.533 32.5071 128.735 32.5071 128.942 32.5918C129.14 32.6766 129.281 32.8225 129.366 33.0296Z" fill="#101010" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M51.2972 32.5798C53.0714 32.5798 54.5096 34.018 54.5096 35.7923C54.5096 37.5664 53.0714 39.0047 51.2972 39.0047C49.523 39.0047 48.0847 37.5664 48.0847 35.7923C48.0847 34.0181 49.523 32.5798 51.2972 32.5798ZM51.2972 34.1798C52.1877 34.1798 52.9097 34.9017 52.9097 35.7923C52.9097 36.6828 52.1877 37.4047 51.2972 37.4047C50.4066 37.4047 49.6847 36.6828 49.6847 35.7923C49.6847 34.9017 50.4066 34.1798 51.2972 34.1798Z" fill="#101010" />
                    <path d="M118.167 34.4419C118.026 34.8185 117.955 35.2186 117.955 35.6423V35.9247C117.955 36.3484 118.035 36.7485 118.195 37.1251C118.355 37.4923 118.572 37.8171 118.845 38.0996C119.127 38.382 119.457 38.5986 119.833 38.7492C120.21 38.9093 120.61 38.9893 121.034 38.9893H123.576C123.802 38.9893 123.995 38.914 124.155 38.7633C124.315 38.6127 124.395 38.4244 124.395 38.1984C124.395 37.9725 124.315 37.7842 124.155 37.6335C123.995 37.4829 123.802 37.4076 123.576 37.4076H121.034C120.836 37.4076 120.648 37.3652 120.469 37.2805C120.29 37.2052 120.13 37.1016 119.989 36.9698C119.857 36.838 119.753 36.6826 119.678 36.5038C119.603 36.3249 119.565 36.1319 119.565 35.9247V35.6423C119.565 35.4352 119.593 35.2422 119.65 35.0633C119.706 34.8844 119.782 34.729 119.876 34.5972C119.979 34.4654 120.102 34.3572 120.243 34.2724C120.375 34.1971 120.521 34.1594 120.681 34.1594C121.001 34.1594 121.255 34.2065 121.443 34.3007C121.622 34.3854 121.731 34.456 121.768 34.5125C121.749 34.6631 121.716 34.7526 121.669 34.7808H120.78C120.554 34.7808 120.361 34.8609 120.201 35.0209C120.041 35.181 119.961 35.374 119.961 35.5999C119.961 35.8165 120.041 36.0048 120.201 36.1648C120.361 36.3155 120.554 36.3908 120.78 36.3908H121.683C121.919 36.3908 122.14 36.339 122.347 36.2354C122.554 36.1319 122.733 35.9954 122.884 35.8259C123.044 35.6564 123.166 35.454 123.251 35.2186C123.345 34.9832 123.392 34.7385 123.392 34.4843C123.392 34.2112 123.322 33.957 123.18 33.7216C123.039 33.4863 122.851 33.2886 122.616 33.1285C122.371 32.9496 122.084 32.8131 121.754 32.719C121.415 32.6248 121.057 32.5777 120.681 32.5777C120.304 32.5777 119.946 32.6578 119.607 32.8178C119.278 32.9685 118.986 33.185 118.732 33.4674C118.497 33.7499 118.308 34.0747 118.167 34.4419Z" fill="#101010" />
                    <path d="M110.753 32.8602C110.47 32.7001 110.16 32.6201 109.821 32.6201H108.451C108.394 32.4601 108.3 32.3329 108.168 32.2388C108.037 32.1352 107.877 32.0835 107.688 32.0835C107.472 32.0835 107.283 32.1588 107.123 32.3094C106.973 32.4695 106.897 32.6578 106.897 32.8743V38.1561C106.897 38.3726 106.973 38.5609 107.123 38.721C107.283 38.8716 107.472 38.9469 107.688 38.9469C107.914 38.9469 108.107 38.8716 108.267 38.721C108.427 38.5609 108.507 38.3726 108.507 38.1561V34.2159H109.821C109.905 34.2159 109.995 34.2536 110.089 34.3289C110.183 34.3948 110.268 34.4937 110.343 34.6255C110.419 34.7479 110.485 34.9032 110.541 35.0915C110.588 35.2704 110.612 35.4728 110.612 35.6988V38.1561C110.612 38.3726 110.692 38.5609 110.852 38.721C111.012 38.8716 111.205 38.9469 111.431 38.9469C111.647 38.9469 111.831 38.8716 111.981 38.721C112.142 38.5609 112.222 38.3726 112.222 38.1561V35.6988C112.222 35.2657 112.16 34.8609 112.038 34.4843C111.906 34.1171 111.732 33.7923 111.515 33.5098C111.299 33.2368 111.045 33.0202 110.753 32.8602Z" fill="#101010" />
                    <path d="M99.8784 35.6423C99.8784 35.2186 99.9491 34.8185 100.09 34.4419C100.232 34.0747 100.42 33.7499 100.655 33.4674C100.909 33.185 101.201 32.9685 101.531 32.8178C101.87 32.6578 102.227 32.5777 102.604 32.5777C102.981 32.5777 103.338 32.6248 103.677 32.719C104.007 32.8131 104.294 32.9496 104.539 33.1285C104.774 33.2886 104.962 33.4863 105.104 33.7216C105.245 33.957 105.316 34.2112 105.316 34.4843C105.316 34.7385 105.268 34.9832 105.174 35.2186C105.09 35.454 104.967 35.6564 104.807 35.8259C104.657 35.9954 104.478 36.1319 104.271 36.2354C104.063 36.339 103.842 36.3908 103.607 36.3908H102.703C102.477 36.3908 102.284 36.3155 102.124 36.1648C101.964 36.0048 101.884 35.8165 101.884 35.5999C101.884 35.374 101.964 35.181 102.124 35.0209C102.284 34.8609 102.477 34.7808 102.703 34.7808H103.593C103.64 34.7526 103.673 34.6631 103.691 34.5125C103.654 34.456 103.546 34.3854 103.367 34.3007C103.178 34.2065 102.924 34.1594 102.604 34.1594C102.444 34.1594 102.298 34.1971 102.166 34.2724C102.025 34.3572 101.903 34.4654 101.799 34.5972C101.705 34.729 101.63 34.8844 101.573 35.0633C101.517 35.2422 101.488 35.4352 101.488 35.6423V35.9247C101.488 36.1319 101.526 36.3249 101.601 36.5038C101.677 36.6826 101.78 36.838 101.912 36.9698C102.053 37.1016 102.213 37.2052 102.392 37.2805C102.571 37.3652 102.759 37.4076 102.957 37.4076H105.499C105.725 37.4076 105.918 37.4829 106.078 37.6335C106.238 37.7842 106.318 37.9725 106.318 38.1984C106.318 38.4244 106.238 38.6127 106.078 38.7633C105.918 38.914 105.725 38.9893 105.499 38.9893H102.957C102.533 38.9893 102.133 38.9093 101.757 38.7492C101.38 38.5986 101.051 38.382 100.768 38.0996C100.495 37.8171 100.279 37.4923 100.119 37.1251C99.9585 36.7485 99.8784 36.3484 99.8784 35.9247V35.6423Z" fill="#101010" />
                    <path d="M99.0735 32.8037C98.9134 32.6436 98.7251 32.5636 98.5086 32.5636H96.2772C95.8536 32.5636 95.4581 32.6436 95.0909 32.8037C94.7238 32.9543 94.3989 33.1709 94.1165 33.4533C93.8529 33.7358 93.641 34.0653 93.481 34.4419C93.3209 34.8185 93.2409 35.2186 93.2409 35.6423V35.9247C93.2409 36.3484 93.3209 36.7485 93.481 37.1251C93.641 37.4923 93.8529 37.8171 94.1165 38.0996C94.3989 38.382 94.7238 38.5986 95.0909 38.7492C95.4581 38.9093 95.8536 38.9893 96.2772 38.9893H98.5086C98.7251 38.9893 98.9134 38.914 99.0735 38.7633C99.2241 38.6127 99.2994 38.4244 99.2994 38.1984C99.2994 37.9725 99.2241 37.7842 99.0735 37.6335C98.9134 37.4829 98.7251 37.4076 98.5086 37.4076H96.2772C96.0795 37.4076 95.8912 37.3652 95.7123 37.2805C95.5429 37.2052 95.3875 37.1016 95.2463 36.9698C95.1239 36.838 95.025 36.6826 94.9497 36.5038C94.8838 36.3249 94.8509 36.1319 94.8509 35.9247V35.6423C94.8509 35.4352 94.8838 35.2422 94.9497 35.0633C95.025 34.8844 95.1239 34.729 95.2463 34.5972C95.3875 34.4654 95.5429 34.3572 95.7123 34.2724C95.8912 34.1971 96.0795 34.1594 96.2772 34.1594H98.5086C98.7251 34.1594 98.9134 34.0841 99.0735 33.9335C99.2241 33.7828 99.2994 33.5945 99.2994 33.3686C99.2994 33.1426 99.2241 32.9543 99.0735 32.8037Z" fill="#101010" />
                    <path d="M87.945 32.5636C88.1616 32.5636 88.3499 32.6436 88.5099 32.8037C88.6606 32.9543 88.7359 33.1426 88.7359 33.3686C88.7359 33.5945 88.6606 33.7828 88.5099 33.9335C88.3499 34.0841 88.1616 34.1594 87.945 34.1594H85.7137C85.516 34.1594 85.3277 34.1971 85.1488 34.2724C84.9793 34.3572 84.824 34.4654 84.6828 34.5972C84.5604 34.729 84.4615 34.8844 84.3862 35.0633C84.3203 35.2422 84.2873 35.4352 84.2873 35.6423V35.9247C84.2873 36.1319 84.3203 36.3249 84.3862 36.5038C84.4615 36.6826 84.5604 36.838 84.6828 36.9698C84.824 37.1016 84.9793 37.2052 85.1488 37.2805C85.3277 37.3652 85.516 37.4076 85.7137 37.4076H87.945C88.1616 37.4076 88.3499 37.4829 88.5099 37.6335C88.6606 37.7842 88.7359 37.9725 88.7359 38.1984C88.7359 38.4244 88.6606 38.6127 88.5099 38.7633C88.3499 38.914 88.1616 38.9893 87.945 38.9893H85.7137C85.29 38.9893 84.8946 38.9093 84.5274 38.7492C84.1602 38.5986 83.8354 38.382 83.553 38.0996C83.2894 37.8171 83.0775 37.4923 82.9175 37.1251C82.7574 36.7485 82.6774 36.3484 82.6774 35.9247V35.6423C82.6774 35.2186 82.7574 34.8185 82.9175 34.4419C83.0775 34.0653 83.2894 33.7358 83.553 33.4533C83.8354 33.1709 84.1602 32.9543 84.5274 32.8037C84.8946 32.6436 85.29 32.5636 85.7137 32.5636H87.945Z" fill="#101010" />
                    <path d="M80.7145 32.7472C80.5638 32.8978 80.4885 33.0861 80.4885 33.3121V38.1278C80.4885 38.3444 80.5638 38.5327 80.7145 38.6927C80.8745 38.8434 81.0628 38.9187 81.2794 38.9187C81.5053 38.9187 81.6983 38.8434 81.8584 38.6927C82.0184 38.5327 82.0985 38.3444 82.0985 38.1278V33.3121C82.0985 33.0861 82.0184 32.8978 81.8584 32.7472C81.6983 32.5966 81.5053 32.5212 81.2794 32.5212C81.0628 32.5212 80.8745 32.5966 80.7145 32.7472Z" fill="#101010" />
                    <path d="M81.8443 31.6739C81.7313 31.8245 81.5524 31.8999 81.3076 31.8999H81.2511C81.0158 31.8999 80.8369 31.8245 80.7145 31.6739C80.5921 31.5233 80.5309 31.3538 80.5309 31.1655C80.5309 30.9866 80.5921 30.8219 80.7145 30.6712C80.8369 30.5206 81.0158 30.4453 81.2511 30.4453H81.3076C81.5524 30.4453 81.7313 30.5206 81.8443 30.6712C81.9667 30.8219 82.0279 30.9866 82.0279 31.1655C82.0279 31.3538 81.9667 31.5233 81.8443 31.6739Z" fill="#101010" />
                    <path d="M76.9155 32.5354C77.3297 32.5354 77.7157 32.6107 78.0735 32.7613C78.4407 32.9214 78.7561 33.1426 79.0197 33.4251C79.2927 33.6981 79.5093 34.0182 79.6693 34.3854C79.8294 34.762 79.9094 35.1574 79.9094 35.5717V35.8541C79.9094 36.2778 79.8294 36.6732 79.6693 37.0404C79.5093 37.4076 79.2927 37.7277 79.0197 38.0007C78.7561 38.2643 78.4407 38.4762 78.0735 38.6362C77.7157 38.7963 77.3297 38.8763 76.9155 38.8763C76.6801 38.8763 76.4777 38.8999 76.3082 38.9469C76.1387 38.994 75.9928 39.0505 75.8704 39.1164C75.7574 39.1823 75.668 39.2482 75.6021 39.3141C75.5456 39.3894 75.5173 39.4459 75.5173 39.4836V40.8817C75.5173 41.1077 75.4373 41.2959 75.2773 41.4466C75.1266 41.6066 74.9383 41.6867 74.7124 41.6867C74.4958 41.6867 74.3075 41.6066 74.1475 41.4466C73.9968 41.2959 73.9215 41.1077 73.9215 40.8817V39.4836C73.9215 39.1823 73.9968 38.8999 74.1475 38.6362C74.3075 38.3632 74.5194 38.1325 74.783 37.9442C75.056 37.7465 75.3761 37.5912 75.7433 37.4782C76.1011 37.3652 76.4918 37.3087 76.9155 37.3087C77.1038 37.3087 77.2873 37.2664 77.4662 37.1816C77.6357 37.1063 77.7863 37.0075 77.9181 36.8851C78.05 36.7438 78.1535 36.5885 78.2288 36.419C78.2947 36.2495 78.3277 36.0613 78.3277 35.8541V35.5717C78.3277 35.3645 78.2947 35.1715 78.2288 34.9927C78.1535 34.8138 78.05 34.6631 77.9181 34.5407C77.7863 34.4089 77.6357 34.3007 77.4662 34.2159C77.2873 34.1406 77.1038 34.1029 76.9155 34.1029H74.7124C74.4958 34.1029 74.3075 34.0276 74.1475 33.877C73.9968 33.7169 73.9215 33.5286 73.9215 33.3121C73.9215 33.0861 73.9968 32.8978 74.1475 32.7472C74.3075 32.606 74.4958 32.5354 74.7124 32.5354H76.9155Z" fill="#101010" />
                    <path d="M71.8173 32.8602C71.5254 32.7001 71.21 32.6201 70.8711 32.6201H69.1905C68.8798 32.6201 68.6492 32.7519 68.4985 33.0155C68.1408 32.7519 67.7453 32.6201 67.3123 32.6201H65.9424C65.8859 32.4506 65.7917 32.3188 65.6599 32.2247C65.5281 32.1211 65.3681 32.0693 65.1798 32.0693C64.9632 32.0693 64.7749 32.1494 64.6149 32.3094C64.4642 32.4695 64.3889 32.6578 64.3889 32.8743V38.1137C64.3889 38.3303 64.4642 38.5138 64.6149 38.6645C64.7749 38.8151 64.9632 38.8904 65.1798 38.8904C65.4057 38.8904 65.5987 38.8151 65.7588 38.6645C65.9188 38.5138 65.9989 38.3303 65.9989 38.1137V34.2159H67.3123C67.397 34.2159 67.4864 34.2536 67.5806 34.3289C67.6747 34.3948 67.7595 34.489 67.8348 34.6114C67.9101 34.7338 67.976 34.8891 68.0325 35.0774C68.0796 35.2563 68.1031 35.4587 68.1031 35.6847V38.1137C68.1031 38.3303 68.1831 38.5138 68.3432 38.6645C68.5032 38.8151 68.6962 38.8904 68.9222 38.8904C69.1387 38.8904 69.3223 38.8151 69.473 38.6645C69.633 38.5138 69.7131 38.3303 69.7131 38.1137V35.6847C69.7131 35.4116 69.6848 35.1574 69.6283 34.9221C69.5812 34.6773 69.5106 34.4419 69.4165 34.2159H70.8711C70.9652 34.2159 71.0641 34.2536 71.1677 34.3289C71.2806 34.4042 71.3748 34.5078 71.4501 34.6396C71.5348 34.762 71.6055 34.9126 71.6619 35.0915C71.709 35.2704 71.7326 35.4681 71.7326 35.6847V38.1561C71.7326 38.3726 71.8126 38.5562 71.9726 38.7069C72.1327 38.8575 72.3257 38.9328 72.5517 38.9328C72.7682 38.9328 72.9518 38.8575 73.1024 38.7069C73.2625 38.5562 73.3425 38.3726 73.3425 38.1561V35.6847C73.3425 35.261 73.2766 34.8609 73.1448 34.4843C73.013 34.1171 72.8341 33.7923 72.6081 33.5098C72.3822 33.2368 72.1186 33.0202 71.8173 32.8602Z" fill="#101010" />
                    <path d="M63.0191 32.5212C63.2451 32.5212 63.4334 32.5966 63.584 32.7472C63.7347 32.8978 63.81 33.0861 63.81 33.3121V38.4526C63.81 38.8951 63.7629 39.3 63.6688 39.6672C63.5652 40.0438 63.4193 40.3686 63.231 40.6416C63.0615 40.9241 62.8544 41.1406 62.6096 41.2912C62.3742 41.4513 62.1106 41.5313 61.8187 41.5313H60.6042C60.3782 41.5313 60.1899 41.4513 60.0393 41.2912C59.8887 41.1312 59.8133 40.9382 59.8133 40.7122C59.8133 40.4957 59.8887 40.3074 60.0393 40.1473C60.1899 39.9967 60.3782 39.9214 60.6042 39.9214H61.7764C61.8234 39.8931 61.8893 39.7943 61.9741 39.6248C62.0588 39.4647 62.1247 39.2435 62.1718 38.961H60.5759C60.1617 38.961 59.771 38.881 59.4038 38.721C59.0366 38.5609 58.7165 38.3491 58.4435 38.0855C58.1798 37.803 57.968 37.4735 57.808 37.0969C57.6479 36.7203 57.5679 36.3202 57.5679 35.8965V33.3121C57.5679 33.0861 57.6432 32.8978 57.7938 32.7472C57.9539 32.5966 58.1422 32.5212 58.3587 32.5212C58.5847 32.5212 58.7777 32.5966 58.9378 32.7472C59.0978 32.8978 59.1778 33.0861 59.1778 33.3121V35.8965C59.1778 36.1036 59.2108 36.2966 59.2767 36.4755C59.352 36.6638 59.4509 36.8239 59.5733 36.9557C59.7145 37.0781 59.8698 37.1769 60.0393 37.2522C60.2088 37.337 60.3876 37.3793 60.5759 37.3793H62.2283V33.3121C62.2283 33.0861 62.3036 32.8978 62.4542 32.7472C62.6049 32.5966 62.7932 32.5212 63.0191 32.5212Z" fill="#101010" />
                    <path d="M55.6047 30.304C55.4541 30.4547 55.3787 30.643 55.3787 30.8689V38.2267C55.3787 38.4527 55.4541 38.641 55.6047 38.7916C55.7648 38.9422 55.953 39.0175 56.1696 39.0175C56.3955 39.0175 56.5886 38.9422 56.7486 38.7916C56.9087 38.641 56.9887 38.4527 56.9887 38.2267V30.8689C56.9887 30.643 56.9087 30.4547 56.7486 30.304C56.5886 30.1534 56.3955 30.0781 56.1696 30.0781C55.953 30.0781 55.7648 30.1534 55.6047 30.304Z" fill="#101010" />
                    <path d="M77.396 9.15527e-05H82.3499L80.1461 6.49383H75.1921L77.396 9.15527e-05Z" fill="#5F71FE" />
                    <path d="M4.78813 10.5801C4.78813 9.26333 3.71076 8.18606 2.39406 8.18606C1.07726 8.18606 0 9.26343 0 10.5801V24.8583C0 26.175 1.07737 27.2523 2.39406 27.2523C3.71085 27.2523 4.78813 26.175 4.78813 24.8583V10.5801Z" fill="#5F71FE" />
                    <path d="M21.025 8.35377C20.0824 8.35377 19.2637 8.91014 18.8804 9.71062C17.5332 8.86678 15.944 8.37375 14.2402 8.36066C14.2171 8.35897 14.194 8.35767 14.1707 8.35657V8.35387H14.1605V8.35617C14.1273 8.35477 14.094 8.35387 14.0605 8.35387H11.8502C11.5025 7.45949 10.631 6.82112 9.6175 6.82112C8.30071 6.82112 7.22344 7.89839 7.22344 9.21509V24.8583C7.22344 26.175 8.30081 27.2523 9.6175 27.2523C10.9343 27.2523 12.0116 26.175 12.0116 24.8583V13.1052H14.0605C14.0941 13.1052 14.1273 13.1043 14.1605 13.1029V13.1053C16.4488 13.1053 18.3038 14.9603 18.3038 17.2486V24.8807C18.3038 26.1873 19.3728 27.2564 20.6794 27.2564C21.9861 27.2564 23.0551 26.1873 23.0551 24.8807V17.2524L23.055 17.2481L23.0551 17.2442C23.0551 15.7494 22.6856 14.3412 22.0333 13.1052H24.8753C24.9089 13.1052 24.9422 13.1043 24.9753 13.1029V13.1053C27.2636 13.1053 29.1186 14.9603 29.1186 17.2486V24.8807C29.1186 26.1873 30.1877 27.2564 31.4943 27.2564C32.8009 27.2564 33.87 26.1873 33.87 24.8807V17.2524L33.8699 17.2481L33.87 17.2442C33.87 12.3607 29.9297 8.39802 25.055 8.36056C25.032 8.35886 25.0088 8.35757 24.9855 8.35647V8.35377H24.9753V8.35607C24.9422 8.35467 24.9088 8.35377 24.8753 8.35377H21.025Z" fill="#5F71FE" />
                    <path d="M2.42742 2.09952H2.3608C1.06239 2.09952 9.93339e-05 3.16181 9.93339e-05 4.46022C9.93339e-05 5.75864 1.06239 6.82092 2.3608 6.82092H2.42742C3.72584 6.82092 4.78813 5.75864 4.78813 4.46022C4.78813 3.16181 3.72584 2.09952 2.42742 2.09952Z" fill="#5F71FE" />
                    <path d="M111.766 10.5801C111.766 9.26332 110.689 8.18606 109.372 8.18606C108.056 8.18606 106.978 9.26342 106.978 10.5801V24.8583C106.978 26.175 108.056 27.2522 109.372 27.2522C110.689 27.2522 111.766 26.175 111.766 24.8583V10.5801Z" fill="#5F71FE" />
                    <path d="M116.917 22.5769C115.636 22.5769 114.589 23.6247 114.589 24.9054C114.589 26.1861 115.636 27.234 116.917 27.234H124.315C127.442 27.234 130 24.6756 130 21.5486C130 18.8925 128.154 16.6469 125.681 16.0308L120.087 14.8404C119.642 14.7269 119.311 14.322 119.311 13.8435C119.311 13.2797 119.771 12.8182 120.334 12.8149C120.341 12.815 120.349 12.8152 120.356 12.8152H127.671C128.952 12.8152 130 11.7673 130 10.4866C130 9.20599 128.952 8.15808 127.671 8.15808H120.274C117.147 8.15808 114.589 10.7165 114.589 13.8435C114.589 16.4907 116.422 18.7302 118.882 19.3552L124.472 20.5444C124.931 20.647 125.278 21.0593 125.278 21.5486C125.278 22.1123 124.818 22.5739 124.255 22.5772C124.247 22.5771 124.24 22.5769 124.232 22.5769H116.917Z" fill="#5F71FE" />
                    <path d="M96.7732 27.2072C98.4407 27.2072 99.8683 26.1287 100.393 24.6348C100.411 24.5951 100.427 24.5545 100.442 24.5134L105.169 11.4093C105.624 10.1736 104.985 8.79046 103.749 8.33558C102.513 7.8807 101.129 8.51947 100.674 9.75516L96.7664 20.5877L92.8605 9.75516C92.4056 8.51947 91.0224 7.8807 89.7868 8.33558C88.5511 8.79046 87.9123 10.1736 88.3672 11.4093L93.0922 24.5134L93.0926 24.5144C93.5818 26.0704 95.0412 27.2072 96.7532 27.2072H96.7732Z" fill="#5F71FE" />
                    <path d="M109.406 2.09961H109.339C108.041 2.09961 106.978 3.1619 106.978 4.46032C106.978 5.75873 108.041 6.82101 109.339 6.82101H109.406C110.704 6.82101 111.766 5.75873 111.766 4.46032C111.766 3.1619 110.704 2.09961 109.406 2.09961Z" fill="#5F71FE" />
                    <path d="M57.1678 8.18608C58.4845 8.18608 59.5618 9.26334 59.5618 10.5801V11.5796L64.9142 8.48943C66.0545 7.83108 67.5261 8.22543 68.1845 9.36572C68.8428 10.506 68.4485 11.9776 67.3082 12.636L59.5618 17.1084V24.8583C59.5618 26.175 58.4846 27.2523 57.1678 27.2523C55.8511 27.2523 54.7737 26.175 54.7737 24.8583V10.5801C54.7737 9.26344 55.851 8.18608 57.1678 8.18608Z" fill="#5F71FE" />
                    <path d="M37.1588 8.35109H43.7317C43.8025 8.35109 43.8725 8.35438 43.9417 8.36057C48.7347 8.49082 52.5803 12.417 52.5803 17.2415L52.5802 17.2496L52.5804 17.2598H52.5745C52.5745 22.1666 48.5968 26.1442 43.6901 26.1442L43.6819 26.1441H43.6781C41.4198 26.1441 39.5836 27.951 39.5359 30.1979C39.5372 30.2292 39.538 30.2606 39.538 30.2922V33.1713C39.538 34.4788 38.4683 35.5486 37.1607 35.5486C35.8532 35.5486 34.7834 34.4788 34.7834 33.1713V30.2922L34.7835 30.2874L34.7834 30.2772C34.7838 30.2163 34.7867 30.1558 34.7916 30.096C34.8882 25.2729 38.8276 21.3928 43.6737 21.3928L43.6819 21.3929H43.6857C45.974 21.3929 47.829 19.5379 47.829 17.2496V17.2458C47.829 14.9575 45.974 13.1026 43.6857 13.1026L37.1588 13.1022C35.8523 13.1022 34.7833 12.0332 34.7833 10.7266C34.7833 9.42006 35.8523 8.35109 37.1588 8.35109Z" fill="#5F71FE" />
                    <path d="M77.8452 22.3006C80.363 22.3006 82.4041 20.2595 82.4041 17.7417C82.4041 15.2239 80.363 13.1828 77.8452 13.1828C75.3274 13.1828 73.2863 15.2239 73.2863 17.7417C73.2863 20.2595 75.3274 22.3006 77.8452 22.3006Z" fill="#5F71FE" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M81.4301 8.92992C84.9058 10.3454 87.3558 13.7574 87.3558 17.7417C87.3558 22.9942 83.0978 27.2523 77.8453 27.2523C72.5928 27.2523 68.3346 22.9942 68.3346 17.7417C68.3346 13.7573 70.7849 10.3451 74.2608 8.92976V8.33924C74.2608 7.54027 74.9085 6.89258 75.7074 6.89258H79.9835C80.7824 6.89258 81.4301 7.54027 81.4301 8.33924V8.92992ZM80.7693 8.68909V8.35155C80.7693 7.95207 80.4455 7.62823 80.046 7.62823H75.7037C75.3042 7.62823 74.9804 7.95207 74.9804 8.35155V8.6702C75.8845 8.38495 76.8469 8.23112 77.8453 8.23112C78.8654 8.23112 79.8481 8.39174 80.7693 8.68909ZM77.8452 10.6342C81.7705 10.6342 84.9526 13.8164 84.9526 17.7417C84.9526 21.667 81.7705 24.8491 77.8452 24.8491C73.9199 24.8491 70.7377 21.667 70.7377 17.7417C70.7377 13.8164 73.9198 10.6342 77.8452 10.6342Z" fill="#5F71FE" />
                    <path d="M73.0271 9.15527e-05H76.4646L74.9667 4.41382L73.0271 9.15527e-05Z" fill="#5F71FE" />
                </svg>
            </a>
            <button className="navbar-toggler" id="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M0 3.75C0 3.41848 0.131696 3.10054 0.366117 2.86612C0.600537 2.6317 0.918479 2.5 1.25 2.5H18.75C19.0815 2.5 19.3995 2.6317 19.6339 2.86612C19.8683 3.10054 20 3.41848 20 3.75C20 4.08152 19.8683 4.39946 19.6339 4.63388C19.3995 4.8683 19.0815 5 18.75 5H1.25C0.918479 5 0.600537 4.8683 0.366117 4.63388C0.131696 4.39946 0 4.08152 0 3.75ZM0 10C0 9.66848 0.131696 9.35054 0.366117 9.11612C0.600537 8.8817 0.918479 8.75 1.25 8.75H18.75C19.0815 8.75 19.3995 8.8817 19.6339 9.11612C19.8683 9.35054 20 9.66848 20 10C20 10.3315 19.8683 10.6495 19.6339 10.8839C19.3995 11.1183 19.0815 11.25 18.75 11.25H1.25C0.918479 11.25 0.600537 11.1183 0.366117 10.8839C0.131696 10.6495 0 10.3315 0 10ZM0 16.25C0 15.9185 0.131696 15.6005 0.366117 15.3661C0.600537 15.1317 0.918479 15 1.25 15H18.75C19.0815 15 19.3995 15.1317 19.6339 15.3661C19.8683 15.6005 20 15.9185 20 16.25C20 16.5815 19.8683 16.8995 19.6339 17.1339C19.3995 17.3683 19.0815 17.5 18.75 17.5H1.25C0.918479 17.5 0.600537 17.3683 0.366117 17.1339C0.131696 16.8995 0 16.5815 0 16.25Z" fill="#5F71FE" />
                </svg>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav" id="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="/">{props.t("navbar.home")}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/achievements">{props.t("navbar.achievements")}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/news">{props.t("navbar.news")}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/mentors">{props.t("navbar.mentors")}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact-us">{props.t("navbar.contact-us")}</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/become-a-student">{props.t("navbar.become-a-student")}</a>
                    </li>
                    <li className="nav-item dropdown" id="lang-dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <span></span>
                            <span>{locales[props.i18n.resolvedLanguage].title}</span>
                            <span></span>

                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            {Object.keys(locales).map((locale) => (
                                <li key={locale}>
                                    <a className="dropdown-item"
                                        style={{ fontWeight: props.i18n.resolvedLanguage === locale ? "bold" : "normal" }}
                                        type="submit"
                                        onClick={() => {
                                            props.i18n.changeLanguage(locale)
                                        }}>
                                        {locales[locale].title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
                {/* <form className="d-flex">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"></input>
                            <button className="btn btn-outline-success" type="submit">Search</button>
                    </form> */}
            </div>
        </div>
    </nav>

}


window.addEventListener('scroll', function() {
    if (window.scrollY === 0) {
        document.getElementById("navbar").style.backgroundColor = "transparent"
        
    } else {
        document.getElementById("navbar").style.backgroundColor = "white"
    }
});

export default NavBar;